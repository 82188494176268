import React from 'react'
import PropTypes from 'prop-types'
import Content from './Content'
import {Link} from 'gatsby'
import NewsCard from './NewsCard'
import StrapiNewsCard from './StrapiNewsCard'
import moment from 'moment'

const BillPageTemplate = ({ title, notice, content, contentComponent, posts, strapiPosts }) => {
  const PageContent = contentComponent || Content

  function Notice({notice}) {
    if(notice.title !== null) {
      return (
        <article className="message">
          <div className="message-header">
            {notice.title}
          </div>
          <div className="message-body">
            <p>{notice.message}</p>
          </div>
        </article>
      )
    }
    else {
      return (
        < ></ >
      )
    }
  }

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-1">
            <div className="section">
              <h1 className="title is-size-1 has-text-weight-bold is-bold-light">
                {title}
              </h1>
              <PageContent className="content" content={content} />
            </div>
            <div className="news-preview-container">
              {strapiPosts.map(strapiPost =>(
                <StrapiNewsCard post={strapiPost} />
              ))}
              {posts.map(({ node: post }) => {
                const { date, expires, autoArchive } = post.frontmatter
                const autoExpire = moment().subtract(6,'months');
                if( expires && moment(expires).isBefore() ) {
                  return (<></>) 
                } else if( autoArchive && moment(date).isBefore(autoExpire)) {
                  return (<></>) 
                } else {
                  return ( <NewsCard key={post.id} post={post} /> )
                }
              })}
              </div>
            <div className="section has-text-centered">
              <hr />
              <Link to="/updates-archive" className="button is-secondary">View Post Archive</Link>
            </div>
            </div>
        </div>
      </div>
    </section>
  )
}

BillPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default BillPageTemplate
