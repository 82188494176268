import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import UpdatesPageTemplate from '../components/UpdatesPageTemplate'
import { HTMLContent } from '../components/Content'

const UpdatesPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <UpdatesPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        notice={post.frontmatter.notice}
        posts={data.allMarkdownRemark.edges}
        strapiPosts={data.allStrapiBlogPost.nodes}
      />
    </Layout>
  )
}

UpdatesPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default UpdatesPage

export const updatesPageQuery = graphql`
  query UpdatesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        notice {
          title
          message
        }
      }
    }
    allStrapiBlogPost(sort: {order: DESC, fields: publishedAt}) {
      nodes {
        id
        title
        publishedAt
        externalLink
        description
        heroImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 300
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
    allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "blog-post"}}}) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            title
            description
            thumbnail {
              childImageSharp {
                fluid (maxWidth: 400){
                  ...GatsbyImageSharpFluid
                }
              }
            }
            expires
            autoArchive
            templateKey
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`
